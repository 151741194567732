<template>
  <!-- 授权用户 -->
  <el-dialog title="选择用户" :visible.sync="dialogFormVisible" :close-on-click-modal="false" @close="$emit('update:dialogVisible', false)" width="800px">
    <el-form :model="searchParams" ref="queryForm" size="small" :inline="true">
      <el-form-item label="用户名称" prop="loginName">
        <el-input
          v-model="searchParams.loginName"
          placeholder="请输入用户名称"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery">搜 索</el-button>
        <el-button @click="resetQuery">重 置</el-button>
      </el-form-item>
    </el-form>
    <el-row>
      <pageTable
        ref="table"
        row-key="userId"
        :columns="columnList"
        :params="searchParams"
        :api-function="this.$api.sysManage.queryUserListData"
        @selectAll="selectAll"
        @select="select"
        :initFalse="true"
      >
        <template slot="status" slot-scope="scope">
          <span>{{ scope.row.status == '0' ? '启用' : '禁用' }}</span>
        </template>
      </pageTable>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('update:dialogVisible', false)">取 消</el-button>
      <el-button type="primary" @click="handleSelectUser">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
// import { unallocatedUserList, authUserSelectAll } from "@/api/system/role";
export default {
  dicts: ['sys_normal_disable'],
  props: {
    // 角色编号
    roleId: {
      type: [Number, String],
    },
    dialogVisible: {
      type: Boolean,
      require,
    },
  },
  data() {
    return {
      dialogFormVisible: this.dialogVisible,
      // 选中数组值
      userIds: [],
      // 未授权用户数据
      userList: [],
      columnList: [
        {
          type: 'selection',
        },
        // {
        //   label: '序号',
        //   type: 'index',
        //   width: 60,
        // },
        {
          label: '用户名',
          prop: 'loginName',
        },
        {
          label: '姓名',
          prop: 'userName',
        },
        {
          label: '角色名称',
          prop: 'roleName',
        },
        {
          label: '所属机构',
          prop: 'agencyName',
        },
        {
          label: '管理机构',
          prop: 'parentName',
        },
        {
          label: '手机号',
          prop: 'phonenumber',
        },
        {
          label: '邮箱',
          prop: 'email',
        },
        {
          label: '状态',
          prop: 'status',
          show: 'template',
          width: '80',
        },
        {
          label: '创建时间',
          prop: 'createTime',
        },
      ],
      selectData: [],
      searchParams: {
        loginName: '',
      },
    };
  },
  methods: {
    selectAll(params) {
      this.selectData = params;
    },
    select(params) {
      this.selectData = params;
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.userIds = selection.map(item => item.userId);
    },
    // 查询表数据
    getList() {
      // unallocatedUserList(this.queryParams).then(res => {
      //   this.userList = res.rows;
      //   this.total = res.total;
      // });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.$refs.table.getPageDataOfAction('search');
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.searchParams = {
        loginName: '',
      };
      this.$nextTick(() => {
        this.handleQuery();
      });
    },
    /** 选择授权用户操作 */
    handleSelectUser() {
      // const roleId = this.queryParams.roleId;
      const userIds = this.userIds.join(",");
      if (userIds == "") {
        this.$message.warning("请选择要分配的用户!");
        return;
      }
      // authUserSelectAll({ roleId: roleId, userIds: userIds }).then(res => {
      //   this.$modal.msgSuccess(res.msg);
      //   if (res.code === 200) {
      //     this.visible = false;
      //     this.$emit("ok");
      //   }
      // });
    }
  }
};
</script>
