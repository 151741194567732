<template>
  <div class="main-container">
    <div class="pt-wrapper">
      <div class="title-wrapper">
        <h1>{{ $route.query.roleName }}</h1>
      </div>
      <table-search
        :model="searchParams"
        label-width="80px"
        :isClear="true"
        :flex="'4'"
        @search="searchFn"
        :isResetByPublic="false"
        @clearSearch="clearSearchFn"
      >
        <search-item default label="">
          <el-input size="small" v-model="searchParams.loginName" placeholder="请输入用户名" clearable />
        </search-item>
      </table-search>
      <div class="bd-content">
        <!-- 操作按钮 -->
        <div class="table-opt">
          <!-- <el-button type="primary" class="el-icon-plus" @click="add">
            添加用户
          </el-button> -->
          <!-- <el-button type="warning" class="el-icon-delete" @click="del">
            批量移出
          </el-button> -->
        </div>
        <pageTable
          ref="table"
          row-key="userId"
          :columns="columnList"
          :params="searchParams"
          :initFalse="true"
          :api-function="$api.sysManage.findByAgency"
          @selectAll="selectAll"
          @select="select"
        >
        </pageTable>
      </div>
    </div>
    <assign-user ref="select" v-if="dialogVisible" :dialogVisible.sync="dialogVisible" @update="updateRowCb(dialogType)" :roleId="searchParams.roleId"></assign-user>
  </div>
</template>

<script>
import { throttle } from '@/utils/public';
import assignUser from "./component/rolecomponent/assignUser";
export default {
  name: 'roleMember',
  components: {
    assignUser,
  },
  data() {
    return {
      selectData: [],
      dialogVisible: false,
      searchParams: {
        roleId: this.$route.query.roleId,
        loginName: '',
      },
      columnList: [
        // {
        //   type: 'selection',
        // },
        {
          label: '用户名',
          prop: 'loginName',
        },
        {
          label: '姓名',
          prop: 'userName',
        },
        {
          label: '所属单位',
          prop: 'parentName',
        },
        {
          label: '管理单位',
          prop: 'agencyName',
        },
      ],
    };
  },
  activated() {
    this.searchFn();
  },
  mounted() {
    this.searchFn();
  },
  methods: {
    clearSearchFn() {
      this.searchParams = {
        roleId: this.$route.query.roleId,
        loginName: '',
      };
      this.$nextTick((res) => {
        this.searchFn();
      });
    },
    selectAll(params) {
      this.selectData = params;
    },
    select(params) {
      this.selectData = params;
    },

    searchFn: throttle(function() {
      Object.assign(this.searchParams, {
        roleId: this.$route.query.roleId
      })
      this.$refs.table.getPageDataOfAction('search');
    }, 10),
    updateRowCb(type) {
      this.searchFn(type);
    },
    add() {
      this.dialogVisible = true;
    },
    // 删除
    del() {
      if (this.selectData.length === 0) {
        this.$message({
          message: '请先勾选数据',
          type: 'warning',
        });
        return;
      }
      this.$msg.confirm('确认批量移出吗？').then(() => {
        this.$api.sysManage
          .cancelAllRoleUser({
            userIds: this.selectData.map((i) => i.userId).join(),
            roleId: this.$route.query.roleId,
          })
          .then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: 'success',
              });
              this.$refs.table.getPageDataOfAction('del', this.selectData.length)
            }
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  background: #fff;
  .pt-wrapper {
    padding: 15px;
    .title-wrapper {
      border-bottom: 1px solid #dfe1e6;
    }
    h1 {
      font-size: 20px;
    }
    .search-wrap {
      margin: 10px 0px;
    }
  }
}
</style>
